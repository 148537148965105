.template-thumb-container {
    width: 20%;
    padding-left: calc($grid-gutter-width / 2);
    padding-right: calc($grid-gutter-width / 2);
    padding-bottom: $grid-gutter-width;
    cursor: pointer;
    position: relative;
    .template-thumb {
        height: 120px;
        width: 100%;
        .bottom-right {
            bottom: -8px;
            right: -8px;
            height: 25px;
            width: 25px;
            border: 2px solid #fff;
        }
    }
    @media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
        width: 25%;
    }
    @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
        width: 33%;
    }
    @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
        width: 100%;
    }
}

.border-dashed {
    border: 1px dashed $secondary;
}

.text-white {
    .border-dashed {
        border: 1px dashed #fff;
    }
}

.fullscreen-modal {
    width: 100%;
    max-width: none;
    margin: 0;
    .modal-content {
        min-height: 100vh;
        border: 0;
        border-radius: 0;
        .modal-body {
            padding: 0 !important;
            margin: 0;
        }
    }
}

.flex-1 {
    flex: 1;
}

.rotate-180 {
    transform: rotate(180deg);
}

.pp-slide-card {
    background-size: cover;
    max-width: 970px;
    margin: 0 auto;
    h1 {
        margin: 0 !important;
    }
    @media screen and (max-width: 1350px) {
        transform: scale(.8);
        transform-origin: top left;
        width: 970px;
        margin-bottom: -70px !important;
    }
    @media screen and (max-width: map-get($grid-breakpoints, 'xl')) {
        transform: scale(.7);
        margin-bottom: -130px !important;
    }
    @media screen and (max-width: 1100px) {
        transform: scale(.6);
        margin-bottom: -190px !important;
    }
    @media screen and (max-width: 900px) {
        transform: scale(.5);
        margin-bottom: -250px !important;
    }
    @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
        transform: scale(.4);
        margin-bottom: -310px !important;
    }
    @media screen and (max-width: map-get($grid-breakpoints, 'xs')) {
        transform: scale(.3);
        margin-bottom: -370px !important;
    }
}

.report-template {
    .pp-slide-card {
        @media screen and (max-width: 1350px) {
            transform: none !important;
            margin-bottom: 0 !important;
            width: 100% !important;
        }
    }
}

.report-export {
    .pp-slide-card {
        @media screen and (max-width: 1350px) {
            transform: scale(1);
            transform-origin: top left;
            width: 970px;
            margin-bottom: 32px !important;
        }
        @media screen and (max-width: map-get($grid-breakpoints, 'xl')) {
            transform: scale(.9);
            margin-bottom: -30px !important;
        }
        @media screen and (max-width: 1100px) {
            transform: scale(.8);
            margin-bottom: -70px !important;
        }
        @media screen and (max-width: 900px) {
            transform: scale(.6);
            margin-bottom: -200px !important;
        }
    }
}

.pp-slide {
    min-height: 500px;
    position: relative;
    .main-title {
        position: absolute;
        top: 40%;
        width: 100%;
    }
    .sub-title {
        position: absolute;
        top: 55%;
        width: 100%;
    }
    .date-field {
        position: absolute;
        width: 50%;
        min-width: 250px;
        bottom: 0;
    }
    .public-DraftEditorPlaceholder-root {
        position: absolute;
        pointer-events: none;
    }
    .footer {
        position: absolute;
        top: 95%;
        width: 90%;
        transform: translate(0, -50%);
    }
    .report-page-number {
        position: absolute;
        top: 99%;
        transform: translate(0, -50%);
        left: 98%;
    }
}

.slide-thumbnail {
    overflow: hidden;
    position: relative;
    padding: 10px;
    max-width: 165px;
    > div {
        position: absolute;
        top: 8px;
        left: 8px;
        width: 970px;
        height: 500px;
        transform: scale(.15);
        transform-origin: top left;
        .top-right {
            display: none;
        }
    }
    .pp-slide {
        height: 100%;
    }
}

.h-0 {
    height: 0 !important;
}

.w-0 {
    width: 0 !important;
}

.text-gray-700 {
    color: $gray-700;
}

.public-DraftStyleDefault-ltr {
    text-align: justify;
    text-align-last: justify;
}
.height-auto{
    .public-DraftEditor-content{
        min-height: auto !important;
    }
}
.DraftEditor-alignLeft .public-DraftStyleDefault-ltr, .DraftEditor-alignCenter .public-DraftStyleDefault-ltr, .DraftEditor-alignRight .public-DraftStyleDefault-ltr {
    text-align-last: unset;
}

.preview-page .body-text p, .preview-page .page-title p {
    margin: 0;
}

.minh-200 {
    min-height: 200px;
}

.square-icon-button {
    height: 30px;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.opacity-0 {
    opacity: 0;
}

#pp-toolbar {
    min-height: 81px;
}

.report-wordcloud {
    height: 150px;
    svg {
        height: 150px;
    }
    &.small {
        height: 70px;
        svg {
            height: 70px;
        }
    }
}

.report-overview-edit-position {
    transform: translateY(-50%);
    right: 20px;
}

.report-right-edit-position {
    transform: translate(50%, -50%);
    right: 0px;
    top: 50%;
}

.bottom-right {
    right: 0px;
    bottom: 0px;
    transform: translate(40%, 40%);
}

.report-export {
    .icon-delete-button {
        display: none !important;
    }
}