.content-deck-controls {
    display: flex;
    padding: 24px 0px;
    background: $gray-200;
}

.content-deck-wrapper {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin-top: 20px;
}

.content-deck {
    background: $gray-200;
    width: 350px;
    height: 950px;
    overflow: auto;
    text-align: left;
    margin: 0 20px;
    flex: 0 0 auto;
    border-radius: 4px;
}

.content-deck-date-picker {
    display: flex;
    padding-right: 20px;
    -webkit-box-align: center;
    align-items: center;
    gap: 4px;
    user-select: none;
}
.content-deck-date-picker_chip {
    width: min-content;
    padding-top: 0;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    padding-bottom: 0;
    padding-left: 10px;
    background: $gray-300;
    border-radius: 50px;
    padding-right: 10px;
    margin: 0;
    margin-right: 15px;
}
.content-deck-date-picker__preset {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.content-deck-date-picker__picker {
    min-width: 0;
}
.content-deck-date-picker__nav {
    display: flex;
    -webkit-box-align: center;
    flex: 1;
    justify-content: flex-end;
    -webkit-box-flex: 1;
    align-items: center;
    -webkit-box-pack: end;
}

.content-deck-header-color {
    height: 5px;
    width: 100%;
}
.content-deck-header {
    position: sticky;
    top: 0;
    background-color: #fff;
    height: 50px;
    color: #eee;
    justify-content: space-between;
    h3 {
        padding: 0 15px;
    }
}
.content-deck-filter {
    position: sticky;
    background: $gray-300;
    top: 40px;
    display: flex;
    justify-content: space-between;
    padding: 15px 15px;
}

.content-deck-header span {
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
}

.content-wrapper {
    padding: 5px;
}

.post-wrap {
    background: #fff;
    margin: 15px 0 0 0 ;
    border: 1px solid $gray-300;
    cursor: pointer;
    border-radius: 4px;
    overflow-y: hidden;
    text-overflow: ellipsis;
}

.post-wrap:hover {
    border: 1px solid $gray-400;
}

.post-wrap-heading {
    display: flex;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 10px;
    margin-top: 10px;
    border-radius: 4px;
    background-color: $gray-100;
}

.post-wrap-footer {
    display: flex;
    padding-top: 10px;
    border-top: 1px dotted $gray-300;
    align-items: center;
    margin-top: 10px;
}

.post-wrap-title {
    text-transform: uppercase;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    font-size: 16px;
}

.post-wrap-body {
    padding: 15px;
}
.post-wrap-icon {
    margin-right: 8px;
    font-size: 16px;
}

.post-wrap-author {
    display: grid;
    grid-template-rows: 5px, 5px;
    margin-bottom: 10px;
}

.post-wrap-author-name {
    grid-column: 1;
    grid-row: 1;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.post-wrap-author-handle {
    grid-column: 1;
    grid-row: 2;
    color: $gray-600;
}
.post-wrap-media {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    background-color: black;
}
.post-wrap-media img {
    max-width: 100%;
    display: block;
}

.open-input{
    width: 150;
    transition: 'all .25s ease-in-out';
}

.close-input{
    width: 10;
    transition: 'all .25s ease-in-out';
}
.custom-narrative-image{
    max-width: 300px;
}
.topic{
    min-height: 36px;
    background-color: #E6F1FE;
    padding: 8px 16px 8px 16px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    align-items: center;
    line-height: 18px;
    .btn{
        min-width: 28px;
        height: 20px;
        display: flex;
        padding: 0px 0px 0px 8px;
        svg{
            color: $primary;
            height: 20px;
            width: 20px;
        }
    }
    &:hover, &.selected{
        background: #E6F1FE;
        border: 1px solid $primary;
    }
    &[color='danger']{
        background-color: #FFEDF0;
        border: 1px solid $danger;
        .btn svg{
            color: $danger
        }
    }
}
.boolean-input-topic{
    display: flex;
    flex-direction: row;
    max-width: 100%;
    min-height: 200px;
    max-height: calc(100vh - 400px);
    @media (max-width: 1272px) {
        max-height: calc(100vh - 600px);
    }
    @media (max-width: 992px) {
        flex-direction: column-reverse;
    }
}
.narrative-right-panel{
    max-height: 100%;
    margin-bottom: 32px;
}
.narrative-name-input{
    border-radius: 4px;
    background-color: transparent;
}
.custom-narrative-input{
    border: 1px solid #e2e7f1;
    border-radius: 4px;
}
.custom-narrative-input:hover,
.custom-narrative-input:focus-within {
    border: 1px solid blue;
    border-radius: 4px;
}
.clear-float{
    clear: both;
}
.left-narrative-container{
    min-width: 40vw;
    z-index: 0;
}
.right-container{
    min-width: 40vw;
    background-color: white;
}
.top-left-new{
    max-width: 20px;
    float: left;
    top: -10px;
    left: 15px;
}