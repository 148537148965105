// 
// popover.scss
//

.label-dropdown {
    .popover.show.bs-popover-auto{
        min-width: 581px;
        max-height: 530px;
    }
    .popover-body {
        padding: 0;
    }
}
.label-box{
    box-shadow: 0px 4px 15px 0px rgba(0,0,0,0.15);
}
