//
// general.scss
//

body:not(.sb-show-main) {
    overflow: hidden;
}

.dont-break-out {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

#telegram-post {
    max-height: 200px;
}

table a {
    display: block;
    text-decoration: none;
}

$cursors: pointer, default, move, grab;

@each $cursor in $cursors {
    .cursor-#{$cursor} {
        cursor: $cursor;
    }
}

.pt-8{
    padding-top: 8rem;
}
.upload-file{
    border: '1px solid #ccc';
    background-color: '#ccc';
    border-radius: '4px';
    display: 'inline-block';
    padding: '6px 12px';
    margin-left: 2px
}

.pre-wrap {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word
}
.small-circle {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
  }

svg.highcharts-root{
    font-family: 'Nunito', sans-serif !important;
}
.content-centered{
    margin: auto;
    margin-top: 10%;
    text-align: center;
}
.sentence-format{
    word-break: break-word;
}

.set-min-width{
    min-width: fit-content;
}

.centered-list {
    list-style-position: inside;
}

.fill-primary {
    fill: $primary;
}
.cluster-list{
    height:300px;
    overflow-y: auto;
}
.cluster-list-items{
    list-style-type:disc;
}
.cluster-main-item::marker{
    font-weight: bold;
}
.cluster-list::-webkit-scrollbar-track {
    border: 1px solid transparent;
    padding: 2px 0;
    background-color:transparent;
  }

  .cluster-list::-webkit-scrollbar {
    width: 10px;
    border-radius: 5px;
  }

  .cluster-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px #006FF9;
    background-color: #006FF9;
    border: 1px solid #006FF9;
  }
  .cluster-line{
    margin-left: -1em;
  }

  .upload-btn-wrapper input[type=file] {
    font-size: 20px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
  .sub-list-cluster{
    list-style-type: none;
  }

  .ctype-selection-box{
    border: none;
    height: 46px;
    width: 46px;
    padding: 0;
  }
.sb-show-main pre {
    color: #fff;
}
.sticky-bottom {
    position: sticky;
    bottom: 0;
}
.sticky-bottom-4 {
    position: sticky;
    bottom: map-get($spacers, 4);
}
.z-index-10{
    z-index: 10
}
.single-line-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 500px;
}
.modal-design-mock{
    margin: 4px !important;
    border: 2px solid grey ;
    border-radius: 4px;
    padding: 4px;
    height: 55px;
    min-width: 140px;
    max-width: 140px;
    word-wrap: break-word;
}

.auth-page-background {
    background-image: url("../../../images/login-background.png");
    background-size: cover;
}

.radial-background {
    background-image: radial-gradient(#FFFFFF, #E6F1FE);
}

.selected-filters{
    max-height: 170px !important;
    overflow-y: scroll;
}
.label-hr-line{
    margin-left: -16px;
    margin-right: -16px;
}

.text-disabled{
    color: #B0B4C5;
}
.text-selected{
    color: #030E3A
}
.label-item {
    border-radius: 4px;;
    background-color: #E6F1FE;
    padding: 8px 12px;
    width: fit-content;
    color: #030E3A;
}

.btn-xs, .btn-group-xs > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.7rem;
    line-height: 1.5;
}

.mw-750 {
    max-width: 750px!important;
}

.set-fit-content {
    max-width: fit-content;
}

.manage-page-main{
    max-height: 94%;
}
.manage-page-sub {
    overflow: hidden;
    overflow-y: scroll;
    max-height: 92%;
}
.saved-filter-row .btn{
    min-width: unset !important;
}
.shadow-light {
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}
.text-wrap {
    text-wrap: wrap;
}
.pt-3px{
    padding-top: 3px;
}
.grid-container-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.grid-container-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.grid-container-4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.toolbar-width{
    max-width: 400px;
    width: 100%;
}
.w-165{
    width: 165px;
}
