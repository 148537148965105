//
// layouts.scss
//

html {
    background-color: #f8f8f8;
}

// Wrapper
#wrapper {
    height: 100%;
    overflow: hidden;
    width: 100%;
    background-color: #f8f8f8;
}

//Content Page
.content-page {
    overflow-x: hidden;
    height: calc(100vh - #{$topbar-height});
    margin-top: $topbar-height;
    .with-side-bar {
        padding-left: $leftbar-width-collapsed;
        transition-duration: 0.2s;
        @media screen and (max-width: map-get($grid-breakpoints, "md")) {
            padding-left: 0;
        }
    }
    .page-padding {
        padding: 32px;
    }
}

.mh-content-page {
    min-height: calc(100vh - #{$topbar-height} + 5px);
}

.h-content-page {
    height: calc(100vh - #{$topbar-height} + 5px);
}

.mh-empty-state {
    min-height: calc(100vh - #{$topbar-height} - 346px);
}

.networks-chart {
    height: calc(100vh - (#{$topbar-height} * 5) + 26px);
}

.auth-layout {
    @media screen and (min-width: map-get($grid-breakpoints, "md")) {
        height: 100vh;
        overflow: auto;
    }
}

.table.entities-table {
    th {
        padding: 16px;
    }
    td {
        padding: 16px;
    }
}

.engagement-context {
    &.row-cols-4 > * {
        flex: 0 0 31%;
        max-width: 31%;
    }
}

// Sidemenu
.left-side-menu {
    width: $leftbar-width-collapsed;
    background: $bg-leftbar-light;
    padding: 0 0 30px 0;
    position: fixed;
    transition-duration: 0.2s;
    box-shadow: 1px 0.05rem 0.01rem rgba(75, 75, 90, 0.075);
    z-index: 99;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    height: -moz-fill-available;
    height: -webkit-fill-available;
    height: stretch;
    .sidebar-content {
        height: 100%;
        padding-bottom: 30px;
    }
}

.left-side-menu:hover, .left-side-menu.no-collapse {
    width: $leftbar-width;
    transition-duration: 0.2s;
    .category {
        max-height: 50px !important;
        padding: 10px 32px 0px 32px !important;
    }
    a > span {
        opacity: 1 !important;
    }
}

.left-side-menu:hover + .with-side-bar, .left-side-menu.no-collapse + .with-side-bar {
    padding-left: $leftbar-width;
    transition-duration: 0.2s;
}

// Sidebar
#sidebar-menu {
    padding-bottom: 30px;
    min-width: max-content;
    > ul {
        list-style-type: none;
        padding-inline-start: 0;
        margin-bottom: 0;
        > li {
            &.mm-active {
                > a {
                    border-left: 3px solid $menu-item-active;
                    color: $menu-item-active;
                    background-color: $menu-item-bg-hover;
                    svg {
                        color: $menu-item-active;
                        fill: rgba($menu-item-active, 0.1);
                    }
                }
            }
            > a {
                color: $gray-400;
                display: block;
                padding: 15px 25px;
                font-size: 15px;
                font-weight: 600;
                position: relative;
                transition: all 0.4s;
                border-left: 3px solid transparent;

                svg {
                    overflow: visible;
                    display: inline-block;
                    width: 20px;
                    margin: 0 20px 0 3px;
                    color: $menu-item;
                    fill: rgba($menu-item, 0.12);
                }

                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                    background-color: $menu-item-bg-hover;

                    svg {
                        color: $menu-item-active;
                        fill: rgba($menu-item-active, 0.1);
                    }
                }
                > span {
                    vertical-align: middle;
                    transition: all 0.2s ease-in-out;
                    opacity: 0;
                }

                i {
                    display: inline-block;
                    line-height: 1rem;
                    margin: 0 10px 0 3px;
                    text-align: center;
                    vertical-align: middle;
                    width: 20px;
                }

                &.active {
                    border-left: 3px solid $menu-item-active;
                    font-weight: bold;
                    background-color: $menu-item-bg-hover;

                    svg {
                        color: $menu-item-active;
                        fill: rgba($menu-item-active, 0.1);
                    }
                }
            }

            > ul {
                padding-left: 43px;

                ul {
                    padding-left: 20px;
                }
            }
        }
    }
    .menu-arrow {
        transition: transform 0.15s;
        position: absolute;
        right: 30px;
        display: inline-block;
        font-family: "unicons";
        text-rendering: auto;
        line-height: 1.3rem;
        font-size: 0.9rem;
        transform: translate(0, 0);
        top: 15px;
        &:before {
            content: "\e82f";
        }
    }

    .badge {
        margin-top: 3px;
    }

    li.mm-active {
        > a {
            > span.menu-arrow {
                transform: rotate(90deg);
            }
        }
    }

    .category {
        padding: 0;
        margin: 0;
        cursor: default;
        font-size: 15px;
        // color: rgba(0, 0, 0, 0.26);
        font-weight: 600;
        transition: all 0.2s ease-in-out;
        overflow: hidden;
        max-height: 0;
    }
}

// user profile
.user-profile {
    .pro-user-name {
        color: $menu-item;
        font-weight: $font-weight-semibold;
    }

    .pro-user-desc {
        text-transform: uppercase;
        font-size: 0.6rem;
        font-weight: 700;
        letter-spacing: 1px;
    }

    .dropdown-toggle {
        svg {
            width: 20px;
            height: 20px;
            color: $secondary;
            background-color: rgba($primary, 1);
            border-radius: 2px;
        }
    }
}

.color-secondary {
    color: $secondary;
}

// Body min-height set

@include media-breakpoint-down(sm) {
    body {
        overflow-x: hidden;
    }
    .left-side-menu {
        display: none;
    }
    .left-side-menu:hover + .with-side-bar, .left-side-menu.no-collapse + .with-side-bar {
        padding-left: 0;
    }
    .sidebar-enable {
        .left-side-menu {
            display: block;
        }
    }
    .content-page {
        margin-left: 0 !important;
        padding: 0 10px;
    }
    .pro-user-name {
        display: none;
    }
    .logo-box {
        display: none;
    }
}

.absolute-bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
}

.absolute-right {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
}

.absolute-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.bottom-middle {
    left: 50%;
    bottom: 0px;
    transform: translate(-50%, 0);
}

.bottom-0 {
    bottom: 0px;
}

.error-page {
    h1 {
        font-size: 60px;
        line-height: 60px;
        margin-bottom: 30px;
        font-weight: 700;
    }
    h2 {
        font-size: 25px;
        font-weight: 600;
    }
    img {
        margin: 0 auto;
        display: block;
    }
}

.scrollbar::-webkit-scrollbar {
    width: 0.5em;
}

.scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(3, 14, 58, 0.1);
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: $gray-400;
    // background-color: $secondary;
    outline: 1px solid slategrey;
}

.scrollbar-small::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.scrollbar-small::-webkit-scrollbar-thumb {
    background-color: $primary;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.transition {
    transition: all 0.25s ease-in-out;
}
.transform-transition {
    transition: transform 0.25s ease-in-out;
}

.mw-190 {
    min-width: 190px !important;
}

.mw-220 {
    min-width: 220px !important;
}

.mw-300 {
    min-width: 300px !important;
}

/*
sizes can be added from variables.scss file
minw-400 = min-width: 400px;
maxw-400 = max-width: 400px;
*/

@each $prop, $abbrev in (min-width: minw, max-width: maxw) {
    @each $size, $length in $sizes {
      .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
    }
}

.w-10-p { width: 10% !important; }
.w-20-p { width: 20% !important; }
.w-50-p { width: 50% !important; }

.maxw-500 {
    max-width: 500px !important;
}

.maxw-350 {
    max-width: 350px !important;
}
.maxw-850 {
    max-width: 850px !important
}

.minh-300 {
    min-height: 300px;
}

.minh-500 {
    min-height: 500px;
}

.minh-42 {
    min-height: 42px;
}

.minh-100 {
    min-height: 100px;
}
.minh-90{
    min-height: 90%;
}
.minh-560 {
    min-height: 560px !important;
}
.minh-630 {
    min-height: 630px;
}
.maxh-100 {
    max-height: 100px !important;
}
.maxh-300 {
    max-height: 300px !important;
}
.maxh-350 {
    max-height: 350px !important;
}

.maxh-420 {
    max-height: 420px !important;
}

.maxh-500 {
    max-height: 500px !important;
}
.maxh-560 {
    max-height: 560px;
}
.pointer-events-none {
    pointer-events: none;
}
.pointer-events-auto {
    pointer-events: auto;
}

.right-center {
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
}

.line-height-1 {
    line-height: 1 !important;
}

.line-height-normal {
    line-height: 1.5 !important;
}

.z-index {
    z-index: 10;
}

.z-index-20 {
    z-index: 20;
}

.z-index-top {
    z-index: 99999;
}

.h-100vh {
    height: 100vh;
}

.top-right {
    top: 0;
    right: 0;
}
.top-right-new{
    max-width: 20px;
    float: right;
    top:0px;
    right: 70px;
}

.top-0 {
    top: 0;
}

.no-white-space {
    white-space: nowrap;
}
// new changes
@media screen and (max-width: map-get($grid-breakpoints, "sm")) {
    .pl-5, .px-5, .pl-4, .px-4 {
        padding-left: 1rem !important;
    }
    .pr-5, .px-5, .pr-4, .px-4 {
        padding-right: 1rem !important;
    }
    .pl-3, .px-3, .pl-2, .px-2 {
        padding-left: .8rem !important;
    }
    .pr-3, .px-3, .pr-2, .px-2 {
        padding-right: .8rem !important;
    }
    .content-page .page-padding {
        padding: 15px 10px;
    }
    .multi-tabs {
        overflow-x: auto;
    }
    .threats .threat-item .threat-item-content {
        padding: 20px 8px !important;
        .content-col {
            flex: 0 0 100% !important;
            margin-top: 10px;
        }
    }
    .threat-ratio > div {
        max-width: 100% !important;
        margin-right: 0 !important;
    }
    .threat-media {
        display: block;
        >div:first-child{
            display: flex;
            align-items: center;
            >div{
                margin-right: 8px;
            }
        }
    }
}

@media screen and (min-width: map-get($grid-breakpoints, "sm")) {
    .sm-flex-1 {
        flex: 1;
    }
}

.rounded-25 {
    border-radius: 25px;
}

.rounded-20 {
    border-radius: 20px;
}

.rounder-4{
    border-radius: 4px;
}

.rounded-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.rounded-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.traffic-signal-small {
    padding: 2px 3px;
    > span:not(:first-child) {
        margin-left: 3px;
    }
    > span {
        padding: 6px;
    }
}

// body > iframe {
//     pointer-events: none;
// }

.fa-brands {
    font-family: "Font Awesome 5 Brands" !important;
}

.flex-basis-0 {
    flex-basis: 0px;
}


@each $size, $length in $spacers {
    @if "#{$size}" != "0" {
      .gap-#{$size} { gap: $length !important; }
    }
}

.labels-container{
    max-height: 158px;
}

.grid_container_narrative{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.leaflet-container {
    font: inherit !important;
}

.locations-marker {
    .selected {
        background-color: $primary;
    }
    .partially-selected {
        background-color: $warning;
    }
}