//
// forms.scss
//

// Form elements (Color and Range)
input.form-control[type="color"],
input.form-control[type="range"] {
    // border: 1px solid form-control[type="color"];
    min-height: 39px;
    border-color: red;
}
.form-control {
  padding: 0.5rem;
  border-radius: 4px;
  color: $gray-400
}
.form-control:hover {
  border-color: $primary;
}
.form-control:disabled, .form-control[readonly] {
  background-color: $gray-300;
  color: $gray-500;
  &:hover{
    border-color: transparent !important;
  }
}
.form-control[state='error']{
  border-color: $danger;
}

// Custom select
.custom-select.is-invalid, .form-control.is-invalid,.custom-select:invalid, .form-control:invalid,
.custom-select.is-valid, .form-control.is-valid, .custom-select:valid,.form-control:valid {
    &:focus {
        box-shadow: none !important;
    }
}
.form-control.is-invalid{
  background-image: none;
}

// Input
input {
    &:focus {
        outline: none;
    }
}

//Custom control input big

.word-short {
  width: max-content;
}

.input-group{
  margin-bottom: 8px;
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: $gray-500 !important;
}
.custom-control-input[color='primary'] ~ .custom-control-label:hover:before, .custom-control-input[color='primary'] ~ .custom-control-label:hover:before {
  border-color: $primary !important;
  box-shadow: 0px 0px 5px 7px $primary-hover !important;
}
.custom-control-input[color='danger'] ~ .custom-control-label:hover:before, .custom-control-input[color='danger'] ~ .custom-control-label:hover:before {
  border-color: $danger !important;
  box-shadow: 0px 0px 5px 7px $primary-hover !important;
}
.custom-control-input[color='danger'] ~ .custom-control-label::before, .custom-control-input[color='danger'] ~ .custom-control-label::before {
  border-color: $danger !important;
}
.custom-control-input[color='danger']:checked ~ .custom-control-label::before{
  border-color: $primary !important;
}
.custom-control-input[color='primary'][disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  border-color: $gray-500 !important;
  box-shadow: none !important;
}
.custom-control-input[color='primary'][disabled]:checked ~ .custom-control-label::before{
  background-color: $gray-500 !important;
}
input[type='radio']:checked {
  box-shadow: 0 0 0 2px $primary;
}

.custom-control-label::before {
  top: 0;
}

input[type='radio'] {
  margin-top: 8px;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 0 2px $primary;
  margin-right: 8px;

}

//HTML date picker design customization

input[type="date"]::-webkit-calendar-picker-indicator {
  display: block;
  background: url(../../../icons/calendar.svg) no-repeat;
  margin-left: 10px;
}

input[type="date"]::-webkit-datetime-edit {
  border-right: 1px solid #e2e7f1;
  height: calc(1.5em + 1rem + 2px);
}

input[type="date"]::-webkit-datetime-edit-fields-wrapper{
  margin:8px;
}
//HTML date picker design customization ends here

.custom-control-input-big.custom-radio.custom-control{
  padding-left: 0px;
  .custom-control-label{
    padding-left: 5px;
  }
}
.custom-control-input ~ .custom-control-label {
  &.green-checkbox::before {
    background-color: #00C0C7;
  }
  &:hover::before {
    width:20px !important;
    height: 20px !important;
    box-shadow: 0px 0px 5px 7px $primary-hover;
  }       
  &::before {   
    width: 20px !important;
    height: 20px !important;
    border-color: $primary;
  }    
  &::after {
    width:20px !important;
    height: 16px !important;
    border-radius: $custom-switch-indicator-border-radius-big;
  }   
}

.custom-switch {
  position: relative;
  left: 8px;
  .custom-control-label {
  padding-left: 8px;
  padding-top: 2px;
    &:hover::before {
      height: 1.5rem !important;
      width: calc(2rem + 0.75rem) !important;
      border: 1px solid $primary !important;
    }
    &::before {
    height: 1.5rem !important;
    width: calc(2rem + 0.75rem) !important;
    border: 1px solid $primary !important;
    border-radius: 3rem !important;
   }
  
   &::after {
    width: calc(1.5rem - 4px) !important;
    height: calc(1.5rem - 4px) !important;
    border-radius: calc(2rem - (1.5rem / 2)) !important;
    top: 2px;
    background-color: $primary;
    }
  
  }
  .custom-control-input:checked ~ .custom-control-label::before {
      background-color: $primary;
  }
  
  .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(calc(1.5rem - 0.25rem)) !important;
  }
}
.checkbox-color-FF6C4C.custom-checkbox.custom-control{
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #FF6C4C;
    border-color: #FF6C4C;
  }
}
.checkbox-color-00C0C7.custom-checkbox.custom-control{
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #00C0C7;
    border-color: #00C0C7;
  }
}
.checkbox-color-D945D0.custom-checkbox.custom-control{
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #D945D0;
    border-color: #D945D0;
  }
}
.checkbox-color-green.custom-checkbox.custom-control{
  .custom-control-label::before, .custom-control-label::after {
    width: 24px !important;
    height: 24px !important;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #00C0C7;
    border-color: #00C0C7;
  }
  .custom-control-label::before {
    border-color: #00C0C7;
    border-width: 2px;
  }
}
.checkbox-color-pink.custom-checkbox.custom-control{
  .custom-control-label::before, .custom-control-label::after {
    width: 24px !important;
    height: 24px !important;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #D945D0;
    border-color: #D945D0;
  }
  .custom-control-label::before {
    border-color: #D945D0;
    border-width: 2px;
  }
}


input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type='radio']:checked:before {
  background: $primary;
}

input[type='radio']:hover{
  // width: 0.781rem;
  // height: 0.781rem;
  width: 20px;
  height: 20px;
  // margin: 0.2rem;
  background: $primary-hover;
  filter: drop-shadow(0px 0px 3px rgba(0, 111, 249, 0.3));
}
input[type='radio']:disabled:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
    background: $gray-300;
}
input[type='radio']:disabled{
  box-shadow: 0 0 0 2px $gray-300;

  // border-color:$gray-300;
}
.input-group-addon-focus{
  border-color: $primary !important;
}

.input-group[border='none'] {
 
  border: 1px solid $gray-300;
  border-radius: 4px;
  &:hover{
    border-color: $primary;
    .input-group-prepend span{
    border-color: $primary !important;
    }
  }
.input-group-prepend span{
  // border: none !important;
  border-top: none !important;
  border-right: none !important;
  border-bottom: none !important;
  // border-right: 1px solid $gray-300;
  background-color: white ;
  margin-right: 1px;
  &:last-child {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
}
  .form-control{
    border: none !important;
  }
  &[state='success'] {
  border-color: $success !important;
    // border-color: $success;
    .input-group-prepend{
      .input-group-text{
        border-color: $success !important;
      }
    }
    }
    &[state='error'] {
  border-color: $danger !important;
      // border-color: $danger;
      .input-group-prepend{
        .input-group-text{
          border-color: $danger !important;
        }
      }
      }
}

.input-group[border='active'] {

  border: 1px solid $primary;
  border-radius: 4px;
  textarea{
    border: none
  }
  input{
    border: none
  }
  .input-group-prepend{
    .input-group-text{
      background-color: white;
      border-left: 0px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-right-color: $primary;
      border-top: 0px;
      border-bottom: 0px;
    }
  }
  .border-right.input-group-prepend{
    .input-group-text{
      background-color: white;
      border-left: 0px;
      border-top: 0px;
      border-bottom: 0px;
      border-right-color: $primary;
      border-radius: 0px;
    }
  }
}
.input-group{
  & input.form-control{
    height: 38px;
  }
}

.form-group{
  // height: 35px;
  margin-bottom: 0px;
}
/* Chrome, Safari, Edge, Opera */
input.no-arrows::-webkit-outer-spin-button,
input.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number].no-arrows {
  -moz-appearance: textfield;
}

.form-check-input {
  margin-top: 0.25rem;
}

.custom-checkbox-small .custom-control-label {
  cursor: pointer;
}

.custom-checkbox-small .custom-control-label::before{
  height: 15px !important;
  width: 15px !important;
  border-radius: 2px;
  left: -1.3rem;
}

.custom-checkbox-small .custom-control-label::after{
  height: 15px !important;
  width: 15px !important;
  left: -1.3rem;
}

input:-webkit-autofill{
  // -webkit-text-fill-color: white !important;
  -webkit-background-color: transparent;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  background-color: transparent;
    -webkit-box-shadow: 0 0 0 30px transparent inset;
}

.input-group-append .dropdown .form-control {
  border: none;
}

.login-form {
  padding: map-get($spacers, 3);
  margin-top: map-get($spacers, 10);
  @media (max-width: 768px) {
    padding: map-get($spacers, 5);
    margin-top: map-get($spacers, 5);
  }
}

.dropdown-form{
  .form-group{
    &:hover{
      background-color: $primary-hover;
    }
  }
}

.search-bar-input {
  input {
    height: 41px !important;
  }
}