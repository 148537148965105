.bs-tooltip-right, .bs-tooltip-auto {
    margin-left: 15px;
    .tooltip-inner.hover {
        max-width: 600px !important;
        background-color: $secondary-alt;
        color: $white;
        border: 1px solid #DBDBDB;
        opacity: 1;
        text-align: start;
        p{
            color: $white;
        }
    }
    .tooltip-inner {
        max-width: 600px !important;
        background-color: $white;
        color: $secondary-alt;
        border: 1px solid #DBDBDB;
        opacity: 1;
        text-align: start;
    }
    .arrow::before {
        border-right-color: #DBDBDB;
        top: -2px;
        border-width: 0rem 1rem 0.6rem 0;
    }
    .arrow::after {
        position: absolute;
        content: "";
        border-color: transparent;
        border-style: solid;
        border-right-color: $white;
        top: -1px;
        right: -3px;
        border-width: 0rem 1rem 0.6rem 0;
    }
    .tooltip-default{
        max-width: 600px !important;
        background-color: #000;
        color: #fff;
        border: 1px solid #000;
        opacity: 1;
        text-align: start;
    }
}
// .text-white .tooltip-inner{
//     p{
//         color: $white;
//     }
// }
.tooltip-inner {
    text-align: start;
    max-width: 530px;
}

.tooltip .bs-tooltip-auto{
    span.display-arrow-imp::before {
        border-right-color: #000;
        top: -2px;
        border-width: 0rem 1rem 0.6rem 0;
        display: inline !important;
    }
    span.display-arrow-imp::after {
        position: absolute;
        content: "";
        border-color: transparent;
        border-style: solid;
        border-right-color: $white;
        top: -1px;
        right: -3px;
        border-width: 0rem 1rem 0.6rem 0;
        display: inline !important;
    }
}

.url-tooltip {
    .tooltip-inner {
        max-width: 100%;
    }
}

.arrow-tooltip {
    .tooltip-inner {
        padding: 8px;
    }
}