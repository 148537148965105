.dropdown-filter {
    padding: 0.5rem;
    border-radius: 4px;
    color: $gray-400;
    border: 1px solid $gray-300;
    height: 40px;
    width: 180px;
    background-color: white;
  }

.dropdown-filter:hover {
    border-color: $primary;
}

.dropdown-filter-menu {
    width: 200px;
    max-height: 256px;
}

.dropdown-filter-menu-checkboxes {
    max-height: 277px;
    width: 500px;
}

.dropdown-filter-menu-items {
    max-height: 200px;
    padding: 16px;
    overflow-y: scroll;
    width: 280px;
    label {
        display: inline-flex !important;
    }
}
.truncate-this {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 210px;

}

.dropdown-filter-menu-search{
    max-height: 360px;
    padding: 16px;
    overflow-y: scroll;
    label {
        display: inline-flex !important;
    }
    .truncate-this {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 210px;
    }
}

.dropdown-filter-multi-checkbox{
    max-height: 280px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    label {
        display: inline-flex !important;
    }
    .truncate-this {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 120px;
    }
}
.dropdown-filter-tabs{
    height: 418px;
    max-width: 433px;
}
.tab-filter{
    max-height:225px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    label {
        display: inline-flex !important;
    }
    .truncate-this {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 88px;
    }
}
.custom-input-filter {
    padding: 2px 16px;
    max-width: 110px;
    height: 31px;
    margin-left: 16px;
    input {
        &::after {
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
        }
    }
    &:hover {
        background-color: $primary-hover;
    }
}
.single-column-dropdown{
    max-height: 280px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    label {
        display: inline-flex !important;
    }
    .truncate-this {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 120px;
    }
}

.dual-column-dropdown{
    max-height: 280px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    label {
        display: inline-flex !important;
    }
    .truncate-this {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 120px;
    }
}

.dropdown-filter-columns {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    label {
        display: inline-flex !important;
    }
    .truncate-this {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 120px;
    }
}

.custom-control-input-checkbox {
    padding: 2px 16px;
    min-width: 169px;
    height: 31px;
    margin-left: 16px;
    input {
        &::after {
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
        }
    }
    &:hover {
        background-color: $primary-hover;
    }
}
.selected-filters-apply{
    max-height: 248px;
    overflow-y: auto;
}